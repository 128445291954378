import React, { useContext, useEffect, useState } from 'react';
import { graphql, Link } from 'gatsby';
import Pager from '../components/utils/pager';
import StoreListItem from '../components/store/store-list-item';
import SortWindow from '../components/store/sort-window';
import { Helmet } from 'react-helmet';

import '../css/store.css';
import ModalCloser from '../components/utils/modal-closer';
import { OnlyPC, OnlySP } from '../helper/media-helper';
import { PATH } from '../helper/config';
import Util from './utils/util';
import { AppContext } from '../context/context';

export enum STORE_SORT_MODE {
  CATEGORY,
  ARTIST
}

const Product: React.FunctionComponent<any> = ({ data, pageContext }) => {
  const context = useContext(AppContext);
  const [isSort, toggleSort] = useState<boolean>(false);
  const [sortMode, setSortMode] = useState<number>(STORE_SORT_MODE.CATEGORY);

  let localePath: string = '';
  if (context.locale === 'en_US') localePath = '/en';

  useEffect(() => {
    context.setLanguage(
      {
        ja: `${PATH.STORE}artist/${encodeURIComponent(Util.GET_VENDOR(pageContext.vendor).en)}`,
        en: `/en${PATH.STORE}artist/${encodeURIComponent(Util.GET_VENDOR(pageContext.vendor).en)}`
      },
      pageContext.locale
    );
  }, []);

  return (
    <>
      <div className="store util__page">
        <Helmet>
          <title>STORE | POST-FAKE</title>
          <meta property="og:title" content={`STORE | POST-FAKE`} />
          <meta name="twitter:site" content={`STORE | POST-FAKE`} />
          <meta name="twitter:title" content={`STORE | POST-FAKE`} />
          <meta property="og:url" content={`https://postfake.com${context.localePath}${PATH.STORE}`} />

          <meta name="robots" content="noindex,nofollow" />
        </Helmet>
        <OnlySP>
          <div className="store__navigation-current-sort ff-univ">
            Artist:
            <strong className="ff-univ-bold">
              {pageContext.isEn ? Util.GET_VENDOR(pageContext.vendor).en : Util.GET_VENDOR(pageContext.vendor).ja}
            </strong>
            <Link to={`${localePath}${PATH.STORE}`} className="link__alpha">
              <span></span>
            </Link>
          </div>
        </OnlySP>
        <nav className="store__navigation">
          <div>
            <OnlyPC>
              <div className="store__navigation-current-sort ff-univ">
                Artist:
                <strong className="ff-univ-bold">
                  {pageContext.isEn ? Util.GET_VENDOR(pageContext.vendor).en : Util.GET_VENDOR(pageContext.vendor).ja}
                </strong>
                <Link to={`${localePath}${PATH.STORE}`} className="link__alpha">
                  <span></span>
                </Link>
              </div>
            </OnlyPC>
            <p className="store__navigation-indicator ff-mono">
              {pageContext.pageNumber * 20 + 1}-{Math.min((pageContext.pageNumber + 1) * 20, pageContext.total)} / {pageContext.total} Items
            </p>
          </div>
          <ul className="store__navigation-list ff-univ-bold">
            <li
              className="store__navigation-list-item link__alpha"
              onClick={() => {
                setSortMode(STORE_SORT_MODE.CATEGORY);
                toggleSort(true);
              }}>
              Category
            </li>
            <li
              className="store__navigation-list-item link__alpha"
              onClick={() => {
                setSortMode(STORE_SORT_MODE.ARTIST);
                toggleSort(true);
              }}>
              Artist
            </li>
          </ul>
        </nav>
        <ul className="store__list">
          {data.allShopifyProduct.edges &&
            data.allShopifyProduct.edges.map((elem: any, i: number) => {
              return <StoreListItem key={`store-list-item-${i}-${elem.cursor}`} node={elem.node} locale={pageContext.locale} />;
            })}
        </ul>
        <Pager context={pageContext} basePath={`/store/artist/${pageContext.vendor}/`} path={`/store/artist/${pageContext.vendor}/page/`} />
      </div>

      {isSort && (
        <>
          <ModalCloser callback={toggleSort} />
          <SortWindow mode={sortMode} toggle={toggleSort} langPath={localePath} isDetail={true} />
        </>
      )}
    </>
  );
};
export default Product;

export const query = graphql`
  query storeVendorListQuery($skip: Int!, $limit: Int!, $vendor: String!) {
    allShopifyProduct(skip: $skip, limit: $limit, filter: { vendor: { eq: $vendor } }, sort: { fields: updatedAt, order: DESC }) {
      edges {
        node {
          title
          vendor
          handle
          featuredImage {
            transformedSrc
          }
          variants {
            availableForSale
          }
        }
      }
    }
  }
`;
