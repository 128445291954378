import React, { useContext, useEffect, useState } from 'react';
import Pager from '../components/utils/pager';
import StoreListItem from '../components/store/store-list-item';
import SortWindow from '../components/store/sort-window';
import { GraphQLClient, gql } from 'graphql-request';
import { Helmet } from 'react-helmet';

import '../css/store.css';
import { PATH } from '../helper/config';
import ModalCloser from '../components/utils/modal-closer';
import { AppContext } from '../context/context';
import { graphql } from 'gatsby';
import API from './utils/api';

export enum STORE_SORT_MODE {
  CATEGORY,
  ARTIST
}

const Product: React.FunctionComponent<any> = ({ data, pageContext }) => {
  const context = useContext(AppContext);
  const [isSort, toggleSort] = useState<boolean>(false);
  const [sortMode, setSortMode] = useState<number>(STORE_SORT_MODE.CATEGORY);
  let localePath: string = '';
  if (context.locale === 'en_US') localePath = '/en';

  useEffect(() => {
    context.setLanguage(
      {
        ja: `${PATH.STORE}`,
        en: `/en${PATH.STORE}`
      },
      pageContext.locale
    );
  }, []);

  return (
    <>
      <div className="store util__page">
        <Helmet>
          <title>STORE | POST-FAKE</title>
          <meta property="og:title" content={`STORE | POST-FAKE`} />
          <meta name="twitter:site" content={`STORE | POST-FAKE`} />
          <meta name="twitter:title" content={`STORE | POST-FAKE`} />
          <meta property="og:url" content={`https://postfake.com${context.localePath}${PATH.STORE}`} />
          <meta name="robots" content="noindex,nofollow" />
        </Helmet>
        <nav className="store__navigation">
          <p className="store__navigation-indicator ff-mono">
            {pageContext.pageNumber * 20 + 1}-{Math.min((pageContext.pageNumber + 1) * 20, pageContext.total)} / {pageContext.total} Items
          </p>
          <ul className="store__navigation-list ff-univ-bold">
            <li
              className="store__navigation-list-item"
              onClick={() => {
                setSortMode(STORE_SORT_MODE.CATEGORY);
                toggleSort(true);
              }}>
              Category
            </li>
            <li
              className="store__navigation-list-item"
              onClick={() => {
                setSortMode(STORE_SORT_MODE.ARTIST);
                toggleSort(true);
              }}>
              Artist
            </li>
          </ul>
        </nav>
        <ul className="store__list">
          {data.allShopifyProduct.edges &&
            data.allShopifyProduct.edges.map((elem: any, i: number) => {
              return <StoreListItem key={`store-list-item-${i}-${elem.cursor}`} node={elem.node} locale={pageContext.locale} />;
            })}
        </ul>
        <Pager context={pageContext} basePath={PATH.STORE} path={PATH.STORE_PAGE} />
      </div>

      {isSort && (
        <>
          <ModalCloser callback={toggleSort} />
          <SortWindow mode={sortMode} toggle={toggleSort} langPath={localePath} />
        </>
      )}
    </>
  );
};
export default Product;

export const query = graphql`
  query storeListQuery($skip: Int!, $limit: Int!) {
    allShopifyProduct(skip: $skip, limit: $limit, sort: { fields: updatedAt, order: DESC }) {
      edges {
        node {
          title
          vendor
          handle
          updatedAt
          featuredImage {
            transformedSrc
          }
          variants {
            availableForSale
          }
        }
      }
    }
  }
`;
