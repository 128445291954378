import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'gatsby';
import { GraphQLClient, gql } from 'graphql-request';

import Util from '../../templates/utils/util';
import { PATH } from '../../helper/config';
import { OnlyPC } from '../../helper/media-helper';
import { AppContext } from '../../context/context';
import LazyImage from '../utils/lazy-image';
import API from '../../templates/utils/api';

const StoreListItem: React.FunctionComponent<{ node: any; locale: string }> = ({ node, locale }) => {
  const [comparePrice, setComparePrice] = useState(null);
  const [price, setPrice] = useState(null);

  const context = useContext(AppContext);
  const langPath: string = locale === 'en_US' ? '/en' : '';

  const title = node.title;
  const pricePrefix: string = locale === 'en_US' ? '$' : '¥';

  useEffect(() => {
    loadPrice();
  }, []);

  const loadPrice = async (): Promise<any> => {
    const endpoint = API.STORE_FRONT;
    const graphQLClient = new GraphQLClient(endpoint, {
      headers: {
        'Content-Type': 'application/json',
        'X-Shopify-Storefront-Access-Token': API.STORE_FRONT_TOKEN
      }
    });

    const query = gql`
      query storeListItemQuery($handle: String!) @inContext(country: JP) {
        productByHandle(handle: $handle) {
          variants(first: 1) {
            edges {
              node {
                compareAtPriceV2 {
                  amount
                  currencyCode
                }
                priceV2 {
                  amount
                  currencyCode
                }
              }
            }
          }
        }
      }
    `;
    const queryEn = gql`
      query storeListItemQuery($handle: String!) @inContext(country: US) {
        productByHandle(handle: $handle) {
          variants(first: 1) {
            edges {
              node {
                compareAtPriceV2 {
                  amount
                }
                priceV2 {
                  amount
                }
              }
            }
          }
        }
      }
    `;
    const variables = {
      handle: node.handle
    };
    const result = await graphQLClient.request(locale === 'en_US' ? queryEn : query, variables);
    setComparePrice({ ...result.productByHandle.variants.edges[0].node.compareAtPriceV2 });
    setPrice({ ...result.productByHandle.variants.edges[0].node.priceV2 });
  };

  return (
    <li className="store__list-item ff-univ">
      <Link to={`${langPath}${PATH.STORE}detail/${node.handle}`}>
        <figure className="store__list-item-figure">
          <LazyImage className="fit fit--contain" src={node.featuredImage.transformedSrc} alt="" />
          {!Util.getAvailable(node.variants) && <p className="store__list-item-sold ff-univ-bold">SOLD OUT</p>}
        </figure>
        <div className="store__list-item-meta">
          <p className="store__list-item-name">
            {title}
            <br />
            by {context.locale === 'en_US' ? Util.GET_VENDOR(node.vendor).en : Util.GET_VENDOR(node.vendor).ja}
          </p>

          {comparePrice && price && comparePrice.amount && (
            <>
              <div className="store__list-item-price-row">
                <p className="store__list-item-price ff-mono">
                  {pricePrefix}
                  {Util.getPriceString(Number(comparePrice.amount))}
                </p>
                <p className="store__list-item-price ff-mono">
                  {pricePrefix}
                  {Util.getPriceString(Number(price.amount))}
                </p>
              </div>
            </>
          )}

          {comparePrice && price && !comparePrice.amount && (
            <p className="store__list-item-price ff-mono">
              {pricePrefix}
              {Util.getPriceString(Number(price.amount))}
            </p>
          )}

          {!comparePrice && !price && <p className="store__list-item-price ff-mono">&nbsp;</p>}
        </div>
      </Link>
      <OnlyPC>
        <div className="store__list-item-hover ff-univ-bold">
          <span className="store__list-item-hover-top">{Util.GET_VENDOR(node.vendor).en}</span>
          <span className="store__list-item-hover-bottom">
            <span>View more</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="80" height="13" viewBox="0 0 80 13">
              <path fill="#fff" d="M80 6.5L59 13V0z" />
              <path fill="#fff" d="M51 6.5L29 13V0z" />
              <path fill="#fff" d="M21 6.5L0 13V0z" />
            </svg>
          </span>
        </div>
      </OnlyPC>
    </li>
  );
};
export default StoreListItem;
