import React, { useContext, useEffect, useState } from 'react';
import { STORE_SORT_MODE } from '../../templates/store-list';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { OnlyPC, OnlySP } from '../../helper/media-helper';

import 'overlayscrollbars/css/OverlayScrollbars.css';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { PATH } from '../../helper/config';
import { AppContext } from '../../context/context';
import Util from '../../templates/utils/util';

export enum SortWindowState {
  DEFAULT,
  SHOW,
  HIDE
}

const SortWindow: React.FunctionComponent<{
  mode: STORE_SORT_MODE;
  toggle: Function;
  langPath: string;
  isDetail?: boolean;
}> = (props) => {
  const qlData = useStaticQuery(graphql`
    query storeSortQuery {
      allShopifyProduct {
        edges {
          node {
            productType
            vendor
          }
        }
      }
    }
  `);

  const data = qlData.allShopifyProduct.edges;

  const context = useContext(AppContext);
  const [productType, setProductType] = useState([]);
  const [vendor, setVendor] = useState([]);
  const [masterVendor, setMasterVendor] = useState([]);
  const [mode, setMode] = useState<number>(SortWindowState.DEFAULT);

  useEffect(() => {
    setMode(SortWindowState.SHOW);
    const tempProduct = [];
    const tempVendorData = [];
    const tempVendor = [];
    for (const node of data) {
      tempProduct.push(node.node.productType);

      const vendorData = Util.GET_VENDOR(node.node.vendor);
      if (vendorData.ja && vendorData.en) {
        tempVendorData.push(vendorData);
      }
    }

    if (!context.localePath.length) {
      tempVendorData.sort((a, b) => {
        if (a.ref < b.ref) return -1;
        if (a.ref > b.ref) return 1;
        return 0;
      });
    } else {
      tempVendorData.sort((a, b) => {
        if (a.en.toUpperCase() < b.en.toUpperCase()) return -1;
        if (a.en.toUpperCase() > b.en.toUpperCase()) return 1;
        return 0;
      });
    }

    for (const vendorName of tempVendorData) {
      if (!context.localePath.length) {
        tempVendor.push(vendorName.ja);
      } else {
        tempVendor.push(vendorName.en);
      }
    }

    setProductType(Array.from(new Set(tempProduct)).sort());
    setVendor(Array.from(new Set(tempVendor)));
    setMasterVendor(tempVendorData);
  }, []);

  let targetClass: string = '';
  if (mode === SortWindowState.DEFAULT) targetClass = 'default';
  if (mode === SortWindowState.SHOW) targetClass = 'show';
  if (mode === SortWindowState.HIDE) targetClass = 'hide';

  return (
    <div
      className={`store__sort-window store__sort-window--${props.mode} ${targetClass} ${props.isDetail ? 'detail' : ''}`}
      onTransitionEnd={() => {
        if (mode === SortWindowState.HIDE) {
          props.toggle(false);
        }
      }}>
      {props.mode === STORE_SORT_MODE.CATEGORY && <CategoryList data={productType} langPath={props.langPath} />}
      {props.mode === STORE_SORT_MODE.ARTIST && <ArtistList data={vendor} master={masterVendor} langPath={props.langPath} />}

      <p
        className="store__sort-window-close link__alpha"
        onClick={() => {
          setMode(SortWindowState.HIDE);
        }}></p>
    </div>
  );
};
export default SortWindow;

const CategoryList: React.FunctionComponent<any> = (props) => {
  return (
    <div className="store__sort-window-category">
      <ul className="store__sort-window-category-list ff-univ-bold">
        {props.data &&
          props.data.map((elem: any, i: number) => {
            return (
              <li className="store__sort-window-category-list-item" key={`store__sort-window-category-list-item-${i}`}>
                <Link className="link__alpha" to={`${props.langPath}${PATH.STORE}product/${elem}`}>
                  {elem}
                </Link>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

const ArtistList: React.FunctionComponent<any> = (props) => {
  return (
    <div className="store__sort-window-artist">
      <h3 className="store__sort-window-artist-caption ff-univ-bold">{props.langPath.length ? 'A - Z' : 'あ - ん'}</h3>

      <div className="store__sort-window-artist-body">
        <OnlyPC>
          <OverlayScrollbarsComponent>
            <div className="store__sort-window-artist-inner">
              <ul className="store__sort-window-artist-list ff-univ-bold">
                {props.data &&
                  props.data.map((elem: any, i: number) => {
                    let vendor: any;
                    for (const refVendor of props.master) {
                      if (!props.langPath.length) {
                        if (refVendor.ja === elem) vendor = refVendor.en;
                      } else {
                        if (refVendor.en === elem) vendor = refVendor.en;
                      }
                    }
                    return (
                      <li className="store__sort-window-artist-list-item" key={`store__sort-window-artist-list-item-${i}`}>
                        <Link className="link__alpha" to={`${props.langPath}${PATH.STORE}artist/${vendor}`}>
                          {elem}
                        </Link>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </OverlayScrollbarsComponent>
        </OnlyPC>
        <OnlySP>
          <div className="store__sort-window-artist-inner">
            <ul className="store__sort-window-artist-list ff-univ-bold">
              {props.data &&
                props.data.map((elem: any, i: number) => {
                  return (
                    <li className="store__sort-window-artist-list-item" key={`store__sort-window-artist-list-item-${i}`}>
                      <Link className="link__alpha" to={`${props.langPath}${PATH.STORE}artist/${elem}`}>
                        {elem}
                      </Link>
                    </li>
                  );
                })}
            </ul>
          </div>
        </OnlySP>
      </div>
    </div>
  );
};
